const viewPortWidth = () => {
    if (process.client) {
        if (window.innerWidth !== undefined && window.innerHeight !== undefined) {
            return window.innerWidth;
        } else {
            return document.documentElement.clientWidth;
        }
    }
    return false;
}

export {viewPortWidth}