<template>
  <Transition>
    <div class="filters-panel" v-if="showFilters">
      <div class="filters-panel__bg" @click="closeFilters"></div>
      <form class="filters" @submit.prevent="submitFilters">
        <button type="button" class="filter-close" @click="closeFilters"></button>
        <div class="title title--filters text-center">Фильтры</div>

        <div class="filters-body">
          <div class="title title--small --compact">Марка, модель, коробка передач</div>
          <div class="form-field">
            <Select
                editable
                v-model="filterParams.brand"
                :options="allFilters.brand"
                placeholder="Марка"
                class="input"
                optionLabel="name"
                optionValue="value"
                @change="updateCarModels"
            >
              <template #value="slotProps">
                <span v-if="slotProps.value.name">
                  {{ slotProps.value.name }}
                </span>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <span class="p-select-option-label">
                  {{slotProps.option.name}}
                </span>
              </template>
            </Select>
          </div>
          <div class="form-field">
            <Select
                editable
                v-model="filterParams.model"
                :options="allFilters.model"
                :disabled="!filterParams.brand || !(allFilters.brand.find(el => el.name === filterParams.brand)?.id)"
                placeholder="Модель"
                class="input"
                optionLabel="name"
                optionValue="value"
            >
              <template #value="slotProps">
                <span v-if="slotProps.value.name">
                  {{ slotProps.value.name }}
                </span>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <span class="p-select-option-label">
                  {{slotProps.option.name}}
                </span>
              </template>
            </Select>
          </div>
          <div class="form-field">
            <Select
                editable
                v-model="filterParams.transmission"
                :options="allFilters.transmission"
                optionLabel="name"
                optionValue="value"
                placeholder="Коробка"
                class="input"
            >
              <template #value="slotProps">
                <span v-if="slotProps.value.name">
                  {{ slotProps.value.name }}
                </span>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <span class="p-select-option-label">
                  {{slotProps.option.name}}
                </span>
              </template>
            </Select>
          </div>

          <div class="title title--small --compact">Мощность двигателя</div>
          <Slider
              v-model="filterParams.power"
              range
              :min="allFilters.power[0]"
              :max="allFilters.power[1]"
              :step="0.1"
              :change="filterParams.power.sort(compareNumbers)"
          />
          <div class="form-field">
            <div class="row">
              <div class="col-6">
                <InputNumber
                    v-model="filterParams.power[0]"
                    :useGrouping="false"
                    inputId="powerFrom"
                    fluid
                    :min="allFilters.power[0]"
                    :max="allFilters.power[1]"
                    suffix=" л"
                    readonly
                />
              </div>
              <div class="col-6">
                <InputNumber
                    v-model="filterParams.power[1]"
                    :useGrouping="false"
                    inputId="powerTo"
                    fluid
                    :min="allFilters.power[0]"
                    :max="allFilters.power[1]"
                    suffix=" л"
                    readonly
                />
              </div>
            </div>
          </div>

          <div class="title title--small --compact">Годы выпуска</div>
          <Slider
              v-model="filterParams.year"
              range
              :min="allFilters.year[0]"
              :max="allFilters.year[1]"
              :step="1"
              :change="filterParams.year.sort(compareNumbers)"
          />
          <div class="form-field">
            <div class="row">
              <div class="col-6">
                <InputNumber
                    v-model="filterParams.year[0]"
                    :useGrouping="false"
                    inputId="yearFrom"
                    fluid
                    :min="allFilters.year[0]"
                    :max="allFilters.year[1]"
                />
              </div>
              <div class="col-6">
                <InputNumber
                    v-model="filterParams.year[1]"
                    :useGrouping="false"
                    inputId="yearTo"
                    fluid
                    :min="allFilters.year[0]"
                    :max="allFilters.year[1]"
                />
              </div>
            </div>
          </div>

          <div class="title title--small --compact">Город</div>
          <div class="form-field">
            <Select
                editable
                v-model="filterParams.city"
                :options="allFilters.city"
                placeholder="Выберите город"
                class="input"
                optionLabel="name"
                optionValue="value"
            >
              <template #value="slotProps">
                <span v-if="slotProps.value.name">
                  {{ slotProps.value.name }}
                </span>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
              <template #option="slotProps">
                <span class="p-select-option-label">
                  {{slotProps.option.name}}
                </span>
              </template>
            </Select>
          </div>

          <div class="title title--small --compact">Пробег</div>
          <Slider
              v-model="filterParams.odometer"
              range
              :min="allFilters.odometer[0]"
              :max="allFilters.odometer[1]"
              :step="1000"
              :change="filterParams.odometer.sort(compareNumbers)"
          />
          <div class="form-field">
            <div class="row">
              <div class="col-6">
                <InputNumber
                    v-model="filterParams.odometer[0]"
                    inputId="kmFrom"
                    suffix=" км"
                    fluid
                    :min="allFilters.odometer[0]"
                    :max="allFilters.odometer[1]"
                />
              </div>
              <div class="col-6">
                <InputNumber
                    v-model="filterParams.odometer[1]"
                    inputId="kmTo"
                    suffix=" км"
                    fluid
                    :min="allFilters.odometer[0]"
                    :max="allFilters.odometer[1]"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-auto">
            <button type="submit" class="button">Показать лоты</button>
          </div>
          <div class="col-auto">
            <button type="button" class="button button--transparent" @click="resetFilters">Сбросить</button>
          </div>
        </div>
      </form>
    </div>
  </Transition>

  <div class="form-block form-block--filters --compact" v-if="!listEmpty">
    <div class="row trades-controls justify-content-between">
      <div class="col-auto">
        <button type="button" class="button --compact button--filters" @click="openFilters">
          Фильтры
          <span v-if="paramsSwiper?.slides?.length">{{paramsSwiper.slides.length}}</span>
        </button>
      </div>
      <div class="col-auto position-relative" v-if="userConfirmed && cookie.authToken.value()">
        <button
            type="button"
            class="button --compact button--light button--download"
            :class="{'--loading': loading}"
            @click="exportResults"
        >
          <span class="d-none d-sm-inline-block">Скачать результаты .xlsx</span>
          <Preloader class="for-button" v-if="loading" />
        </button>
      </div>
    </div>
    <swiper
        class="trades-controls__filters"
        :slides-per-view="'auto'"
        :space-between="16"
        @swiper="onSwiperParams"
    >
      <template v-for="(value, key) in filterParams" :key="key">
        <swiper-slide
            v-if="value && (value.constructor !== Array) && allFilters[key].find(el => el.name === value)?.id"
        >
          <div class="trades-controls__filtersItem">
            {{value}}
            <button
                class="remove"
                type="button"
                @click="clearFilter(key)"
            ></button>
          </div>
        </swiper-slide>
        <template v-if="key === 'power'">
          <swiper-slide
              v-if="!(filterParams.power[0] === allFilters.power[0] && filterParams.power[1] === allFilters.power[1])"
          >
            <div class="trades-controls__filtersItem">
              {{filterParams.power[0].toLocaleString()}} л - {{filterParams.power[1].toLocaleString()}} л
              <button
                  class="remove"
                  type="button"
                  @click="
                  filterParams.power[0] = allFilters.power[0];
                  filterParams.power[1] = allFilters.power[1];
                  filtersQuery.engineSizes.from = allFilters.power[0];
                  filtersQuery.engineSizes.to = allFilters.power[1];
                  updateTradeItems()"
              ></button>
            </div>
          </swiper-slide>
        </template>
        <template v-if="key === 'year'">
          <swiper-slide
              v-if="!(filterParams.year[0] === allFilters.year[0] && filterParams.year[1] === allFilters.year[1])"
          >
            <div class="trades-controls__filtersItem">
              {{filterParams.year[0]}} - {{filterParams.year[1]}}
              <button
                  class="remove"
                  type="button"
                  @click="
                  filterParams.year[0] = allFilters.year[0];
                  filterParams.year[1] = allFilters.year[1];
                  filtersQuery.years.from = allFilters.year[0];
                  filtersQuery.years.to = allFilters.year[1];
                  updateTradeItems()"
              ></button>
            </div>
          </swiper-slide>
        </template>
        <template v-if="key === 'odometer'">
          <swiper-slide v-if="filterParams.odometer[0] !== allFilters.odometer[0]">
            <div class="trades-controls__filtersItem">
              от {{filterParams.odometer[0]}} км
              <button
                  class="remove"
                  type="button"
                  @click="
                  filterParams.odometer[0] = allFilters.odometer[0];
                  filtersQuery.mileages.from = allFilters.odometer[0];
                  updateTradeItems()"
              ></button>
            </div>
          </swiper-slide>
          <swiper-slide v-if="filterParams.odometer[1] !== allFilters.odometer[1]">
            <div class="trades-controls__filtersItem">
              до {{filterParams.odometer[1]}} км
              <button
                  class="remove"
                  type="button"
                  @click="
                  filterParams.odometer[1] = allFilters.odometer[1];
                  filtersQuery.mileages.to = allFilters.odometer[1];
                  updateTradeItems()"
              ></button>
            </div>
          </swiper-slide>
        </template>
      </template>
    </swiper>
    <div class="row align-items-center trades-filters__statuses">
      <div class="col-12 col-xxl-auto" v-if="userConfirmed && cookie.authToken.value() && (filterStatuses !== null || filterDeals !== null)">
        <div class="row gx-0 align-items-center">
          <div class="col-12 col-lg-auto">
            <span class="status-label">Статус ставки:</span>
          </div>
          <div class="col-12 col-lg" v-if="filterStatuses !== null">
            <swiper
                class="trades-status"
                @swiper="onSwiperStatus"
                :slides-per-view="'auto'"
                :space-between="16"
                :slide-to-clicked-slide="true"
                :breakpoints="{
                  '1200': {
                    spaceBetween: 24
                  }
                }"
            >
              <swiper-slide
                  v-for="(value, key) in filterStatuses"
                  :key="key"
              >
                <div class="checkbox-field">
                  <input :id="`status-${key}`" type="checkbox" v-model="value.checked" @change="updateStatuses(key)" />
                  <label :for="`status-${key}`">{{value.name}}</label>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="col-12 col-lg" v-if="filterDeals !== null">
            <swiper
                class="trades-status"
                @swiper="onSwiperStatus"
                :slides-per-view="'auto'"
                :space-between="16"
                :slide-to-clicked-slide="true"
                :breakpoints="{
                  '1200': {
                    spaceBetween: 24
                  }
                }"
            >
              <swiper-slide
                  v-for="(value, key) in filterDeals"
                  :key="key"
              >
                <div class="checkbox-field">
                  <input :id="`status-${key}`" type="checkbox" v-model="value.checked" @change="updateDeals(key)" />
                  <label :for="`status-${key}`">{{value.name}}</label>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
      <div class="col-12 col-xxl">
        <VueDatePicker
          class="trades-filters__date"
          v-model="dateRange"
          locale="ru-RU"
          :enable-time-picker="false"
          range
          placeholder="Выберите дату"
          :auto-apply="true"
          multi-calendars
          format="dd.MM.yyyy"
          @update:model-value="updateTradeItems"
        />
      </div>
    </div>
  </div>

  <moderator-notice compact v-if="!cookie.authToken.value()">
    <nuxt-link to="/registration">Зарегистрируйтесь</nuxt-link> или
    <nuxt-link to="/login">войдите</nuxt-link> в личный кабинет для доступа к лотам
  </moderator-notice>
  <moderator-notice compact v-else-if="!userConfirmed">
    Ставки станут доступны после заполнения вашего профиля
    в <nuxt-link to="/cabinet">личном кабинете</nuxt-link> и подтверждения модератором.
    <span v-if="!!remainingTime">Если вы не заполните профиль, то он будет деактивирован через <b>{{ remainingTime }} дней.</b></span>
  </moderator-notice>

  <div class="form-block form-block--trades --compact">
    <div class="row gx-0 align-items-center trades-filters" v-if="!listEmpty">
      <div class="col-12 col-xxl-auto order-xxl-last">
        <div class="row gx-0 align-items-center trades-filters__controls">
          <div class="col-12 col-md trades-filters__item">
            <form class="trades-filters__search" @submit.prevent="filterSearch">
              <input type="text" v-model="searchQuery" class="input" placeholder="Поиск">
              <button type="submit" class="submit"></button>
            </form>
          </div>
          <div class="col-12 col-md-auto trades-filters__item filter-pages">
            <button
                type="button"
                class="filter-pages__item"
                v-for="(quantity, index) in q_pages"
                :key="index"
                :class="{'active': pagesLimit === quantity}"
                @click="updatePageLimit(quantity)"
            >{{quantity}}</button>
          </div>
        </div>
      </div>
      <div class="col-12 col-xxl">
        <div class="row gx-0 justify-content-between align-items-center">
          <div class="col-auto trades-filters__item" v-if="downloadable">
            <div class="checkbox-field">
              <input type="checkbox" id="download-all" v-model="downloadAll" @change="selectAll" />
              <label for="download-all"><br></label>
            </div>
          </div>
          <div class="col trades-filters__item">
            <button
                type="button"
                class="trades-filters__sort"
                @click="sortExpanded = !sortExpanded"
            >
              <img :src="sort" :class="{'asc': activeSort.direction === 'asc'}" />
              {{activeSort.name}}
            </button>
            <Listbox
                v-model="activeSort"
                :options="sortVariants"
                optionLabel="name"
                v-show="sortExpanded"
                @change="sortExpanded = false; updateTradeItems()"
                ref="listBox"
                listStyle="max-height:320px"
            >
              <template #option="slotProps">
                <div class="d-flex align-items-center">
                  <img :alt="slotProps.option.name" :src="sort" :class="{'asc': slotProps.option.direction === 'asc'}" />
                  <div>{{ slotProps.option.name }}</div>
                </div>
              </template>
            </Listbox>
          </div>
          <div class="col-auto trades-filters__item">
            <button
              type="button"
              class="button --compact refresh mr-0.5em"
              @click="refreshLots"
            >
              Обновить список
            </button>
          </div>
          <div class="col-auto trades-filters__item">
            Показано лотов: <span class="total">{{tradeItems.length}} / {{totalItems}}</span>
          </div>
        </div>
      </div>
    </div>

    <Preloader class="my-4" v-if="!pageLoaded" />
    <div class="my-4 text-center" v-else-if="!tradeItems.length">
      Лоты не найдены
    </div>
    <template v-else>
      <div
          class="car-item__row"
          v-for="(item, index) in tradeItems"
          :key="item.id"
      >
        <div class="checkbox-field" v-if="downloadable">
          <input
              type="checkbox"
              :id="`item-${item.id}`"
              v-model="downloadableItems[index]"
              @change="selectDownloads(item, index)"
          />
          <label :for="`item-${item.id}`"> </label>
        </div>
        <car-item :item="item" :anonymous="!cookie.authToken.value() || !userConfirmed" />
      </div>
    </template>

    <div class="text-center">
      <vue-awesome-paginate
          v-if="totalItems > perPage"
          :total-items="totalItems"
          v-model="currentPage"
          :items-per-page="perPage"
          :max-pages-shown="5"
          type="link"
          link-url="/?page=[page]"
          prev-button-content="Назад"
          next-button-content="Далее"
          :hide-prev-next-when-ends="false"
      />
    </div>
  </div>

  <Transition name="total">
    <TotalDownloads
        class="d-none d-xl-block"
        v-if="downloadable && downloadableItems.indexOf(true) > -1"
        :selected="selectedItems"
        :total="downloadableItems.length"
        :price="totalPrice"
        :no-docs="totalDocs"
        :url="props.url"
        :documents="downloadsID"
    />
  </Transition>
  <Teleport to="body">
    <Transition name="total">
      <TotalDownloads
          class="d-xl-none"
          v-if="downloadable && downloadableItems.indexOf(true) > -1"
          :selected="selectedItems"
          :total="downloadableItems.length"
          :price="totalPrice"
          :no-docs="totalDocs"
          :url="props.url"
          :documents="downloadsID"
      />
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
import {viewPortWidth} from "~/utils/window-size";
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { onClickOutside } from '@vueuse/core'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import DatePicker from 'primevue/datepicker';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Select from 'primevue/select';
import Slider from 'primevue/slider';
import InputNumber from 'primevue/inputnumber';
import Listbox from "primevue/listbox";
import CarItem from "~/components/trades/CarItem.vue";
import Preloader from "~/components/page/Preloader.vue";

import sort from '~/components/layout/img/trades/icon-sort.svg';
import {VueAwesomePaginate} from "vue-awesome-paginate";
import {ApiRequest} from "~/utils/api-request";
import cookie from "~/utils/cookie";
import ModeratorNotice from "~/components/cabinet/moderatorNotice.vue";
import {getUserBids} from "~/utils/api/user-bids";
import TotalDownloads from "~/components/trades/TotalDownloads.vue";
import {loadUserInfo, user} from "~/utils/user";

const props = defineProps({
  url: {
    type: String,
    default: 'all'
  },
  filterStatuses: {
    type: [Object, null],
    default: null,
  },
  filterDeals: {
    type: [Object, null],
    default: null,
  },
  userConfirmed: {
    type: Boolean,
    default: true,
  },
  remainingTime: {
    type: Number,
    default: 30
  },
  downloadable: {
    type: Boolean,
    default: false,
  },
  defaultSorting: {
    type: String,
    default: 'asc',
  }
});

const router = useRouter();
const route = useRoute();

const listBox = ref(null);

const listEmpty = ref(false);
const tradeItems = ref([]);
const downloadableItems = ref([]);
const downloadAll = ref(false);
const tradePagination = ref({});
const pageLoaded = ref(false);
const currentPage = ref(1);
const perPage = ref(1);
const totalItems = ref(1);

const q_pages = ref([15, 25, 50]);
const pagesLimit = ref(15);
const searchQuery = ref('');
const sortVariants = [
  {
    name: 'По умолчанию',
    by: 'end',
    direction: props.defaultSorting,
  },
  {
    name: 'Цена: по возрастанию',
    by: 'price',
    direction: 'asc',
  },
  {
    name: 'Цена: по убыванию',
    by: 'price',
    direction: 'desc',
  },
  {
    name: 'Время завершения: по возрастанию',
    by: 'end',
    direction: 'asc',
  },
  {
    name: 'Время завершения: по убыванию',
    by: 'end',
    direction: 'desc',
  },
  {
    name: 'Время старта: по возрастанию',
    by: 'start',
    direction: 'asc',
  },
  {
    name: 'Время старта: по убыванию',
    by: 'start',
    direction: 'desc',
  },
  {
    name: 'Марка: по возрастанию',
    by: 'modelName',
    direction: 'asc',
  },
  {
    name: 'Марка: по убыванию',
    by: 'modelName',
    direction: 'desc',
  },
];
const activeSort = ref(sortVariants[0]);

const sortExpanded = ref(false);
const showFilters = ref(false);
onClickOutside(listBox, () => {
  if (sortExpanded.value) {
    sortExpanded.value = false;
  }
})

watch(showFilters,() => {
  if (showFilters.value) {
    document.body.classList.add('filters-shown')
  } else {
    document.body.classList.remove('filters-shown')
  }
})

const allFilters = ref({
  brand: [],
  model: [],
  transmission: [],
  power: [1.4, 2.2],
  year: [2000, 2024],
  city: [],
  odometer: [0, 1000000],
});

const filterParams = ref({
  brand: '',
  model: '',
  transmission: '',
  power: [1.4, 2.2],
  year: [2000, 2024],
  city: '',
  odometer: [0, 1000000],
});
const initialFilterParams = ref({});

const filtersQuery = ref({
  brandsId: [],
  modelsId: [],
  transmissionsId: [],
  engineSizes: {
    from: null,
    to: null
  },
  years: {
    from: null,
    to: null
  },
  citiesId: [],
  mileages: {
    from: null,
    to: null
  },
});

const dateBreakpoint = 576;
let dateRange = ref(null);
let month_qnt = ref((viewPortWidth() < dateBreakpoint) ? 1 : 2);

const handleResize = () => {
  month_qnt.value = (viewPortWidth() < dateBreakpoint) ? 1 : 2;
}
const compareNumbers = (a, b) => {
  return a - b;
}

const statusSwiper = ref(null);
const paramsSwiper = ref(null);
const onSwiperStatus = (swiper) => {
  statusSwiper.value = swiper;
};
const onSwiperParams = (swiper) => {
  paramsSwiper.value = swiper;
};

const selectedStatuses: Array<String> = [];
const selectedDeals: Array<String> = [];

const updateStatuses = (code) => {
  if (props.filterStatuses[code].checked) {
    selectedStatuses.push(code);
  } else {
    selectedStatuses.splice(selectedStatuses.indexOf(code), 1);
  }
  updateTradeItems();
}

const updateDeals = (code) => {
  if (props.filterDeals[code].checked) {
    selectedDeals.push(code);
  } else {
    selectedDeals.splice(selectedDeals.indexOf(code), 1);
  }
  updateTradeItems();
}

const filterSearch = () => {
  currentPage.value = 1;
  updateTradeItems();
}

const updatePageLimit = (limit) => {
  pagesLimit.value = limit;
  currentPage.value = 1;
  updateTradeItems();
}

const updateTradeItems = () => {
  tradeItems.value = [];
  tradePagination.value = {};
  pageLoaded.value = false;
  router.push(`${route.path}?page=${currentPage.value}`);
  let api_query = {
    search: searchQuery.value.trim(),
    limit: pagesLimit.value,
    page: currentPage.value,
    order: {
      by: activeSort.value.by,
      direction: activeSort.value.direction,
    },
    position: selectedStatuses,
    dealStatuses: selectedDeals,
    date: {
      from: (dateRange.value !== null && dateRange.value.length) ? (dateRange.value[0].getTime()/1000) : null,
      to: (dateRange.value !== null && dateRange.value[1] !== null) ? (dateRange.value[1].getTime()/1000) : null,
    },
    filters: filtersQuery.value,
  }
  getTradeItems('lots/' + props.url, api_query, false);
}

const getTradeItems = async(api_url, api_query = {}, updateLength = false) => {
  let apiRequest = new ApiRequest('get');
  apiRequest.setUri(api_url);
  apiRequest.setQuery(api_query);

  await loadUserInfo();

  apiRequest.onSuccess((data) => {
    Object.entries(data.lots).map(([key, lot]) => {
      data.lots[key].bid = null;
      data.lots[key].betData = {
        current: lot.currentPrice,
        step: lot.biddingStep,
        fee: (user.init) ? (user.setting.commission / 100) : 0,
      }
    });

    // console.log(data);
    tradeItems.value = data.lots;
    downloadableItems.value.length = data.lots.length;
    if (data.lots.length) {
      downloadableItems.value.fill(false);
    }

    tradePagination.value = data.pagination;
    currentPage.value = tradePagination.value.currentPage;
    perPage.value = tradePagination.value.perPage;
    totalItems.value = tradePagination.value.total;

    pageLoaded.value = true;

    if (cookie.authToken.value() && props.userConfirmed) {

      let activeIds = data.lots.filter(lot => lot.isActive).map(lot => lot.id)

      if (activeIds.length > 0) {
        getUserBids(activeIds).then((data) => {

          Object.entries(data.lots).map(([key, lotBid]) => {

            for (let lotKey in tradeItems.value) {

              let lot = tradeItems.value[lotKey];
              if (lot.id !== lotBid.id) {
                continue;
              }

              tradeItems.value[lotKey].bid = lotBid;
              tradeItems.value[lotKey].betData = {
                current: lotBid.currentPrice,
                step: lotBid.biddingStep,
                fee: (user.init) ? (user.setting.commission / 100) : 0,
              }

              break;

            }

          });

        });
      }

    }

  });

  apiRequest.onError((error) => {
    // errors
    pageLoaded.value = false;
  });

  let data = await apiRequest.request();
  // console.log(data);

  if (updateLength) {
    listEmpty.value = !(tradeItems.value.length);
  }
}

enum Queries {
  brand = 'brandsId',
  model = 'modelsId',
  transmission = 'transmissionsId',
  city = 'citiesId',
}

const clearFilter = (prop) => {
  if (['brand', 'model', 'transmission', 'city'].indexOf(prop) > -1){
    filterParams.value[prop] = '';
    // console.log(filtersQuery.value[Queries[prop]]);
    filtersQuery.value[Queries[prop]] = [];
    if (prop === 'brand') {
      filterParams.value.model = '';
      filtersQuery.value.modelsId = [];
    }
    updateTradeItems();
  }
}

const resetFilters = () => {
  for (let prop in filterParams.value) {
    if (['brand', 'model', 'transmission', 'city'].indexOf(prop) > -1){
      filterParams.value[prop] = '';
    }
  }

  filterParams.value.power = [allFilters.value.power[0], allFilters.value.power[1]];
  filterParams.value.year = [allFilters.value.year[0], allFilters.value.year[1]];
  filterParams.value.odometer = [allFilters.value.odometer[0], allFilters.value.odometer[1]];

  filtersQuery.value.brandsId = [];
  filtersQuery.value.modelsId = [];
  filtersQuery.value.transmissionsId = [];
  filtersQuery.value.citiesId = [];
  filtersQuery.value.engineSizes.from = allFilters.value.power[0];
  filtersQuery.value.engineSizes.to = allFilters.value.power[1];
  filtersQuery.value.years.from = allFilters.value.year[0];
  filtersQuery.value.years.to = allFilters.value.year[1];
  filtersQuery.value.mileages.from = allFilters.value.odometer[0];
  filtersQuery.value.mileages.to = allFilters.value.odometer[1];

  updateTradeItems();
  showFilters.value = false;
};

const getFilters = async() => {
  let apiRequest = new ApiRequest('get', 'lots/' + props.url + '/filter');

  apiRequest.onSuccess((data) => {
    // console.log(data);
    allFilters.value.brand = data.filters.brands;
    allFilters.value.model = data.filters.models;
    allFilters.value.city = data.filters.cities;
    allFilters.value.transmission = data.filters.transmissions;

    allFilters.value.power = [data.filters.engineSizes.from, data.filters.engineSizes.to];
    filterParams.value.power = [data.filters.engineSizes.from, data.filters.engineSizes.to];
    filtersQuery.value.engineSizes.from = data.filters.engineSizes.from;
    filtersQuery.value.engineSizes.to = data.filters.engineSizes.to;

    allFilters.value.year = [data.filters.years.from, data.filters.years.to];
    filterParams.value.year = [data.filters.years.from, data.filters.years.to];
    filtersQuery.value.years.from = data.filters.years.from;
    filtersQuery.value.years.to = data.filters.years.to;

    allFilters.value.odometer = [data.filters.mileages.from, data.filters.mileages.to];
    filterParams.value.odometer = [data.filters.mileages.from, data.filters.mileages.to];
    filtersQuery.value.mileages.from = data.filters.mileages.from;
    filtersQuery.value.mileages.to = data.filters.mileages.to;

    // initialFilterParams.value = { ...filterParams.value };
    initialFilterParams.value = JSON.parse(JSON.stringify(filterParams.value));
  });

  apiRequest.onError((error) => {
    // errors
  });

  let data = await apiRequest.request();
  // console.log(data);
}

const updateCarModels = async() => {
  filterParams.value.model = '';
  let brandId = allFilters.value.brand.find(el => el.name === filterParams.value.brand)?.id;
  // console.log(brandId);
  if (brandId){
    let apiRequest = new ApiRequest('get', 'lots/' + props.url + '/filter',{
      filters: {
        brandsId: [brandId],
      }
    });

    apiRequest.onSuccess((data) => {
      // console.log(data);
      allFilters.value.model = data.filters.models;
    });

    apiRequest.onError((error) => {
      // errors
    });

    let data = await apiRequest.request();
  }

}

const totalPrice = ref(0);
const totalDocs = ref(0);
const selectedItems = ref(0);
const downloadsID = ref([]);
const selectDownloads = (item, index) => {
  let canDownload = item.dealStatuses?.deal_payment_waiting?.isActive && !item.dealStatuses?.deal_paid_with_preparing_closing_documents?.isActive;

  if (downloadableItems.value[index]){
    selectedItems.value++;
    if (!canDownload) totalDocs.value++;
    totalPrice.value += item.betData.current;
    downloadsID.value.push(item.id);
  } else {
    selectedItems.value--;
    if (!canDownload) totalDocs.value--;
    totalPrice.value -= item.betData.current;
    downloadsID.value.splice(downloadsID.value.indexOf(item.id), 1);
  }
  if (selectedItems.value !== downloadableItems.value.length) {
    downloadAll.value = false;
  }
}
const selectAll = () => {
  let canDownload = false;
  downloadableItems.value.fill(downloadAll.value);
  selectedItems.value = (downloadAll.value) ? downloadableItems.value.length : 0;
  totalDocs.value = 0;
  totalPrice.value = 0;
  downloadsID.value.length = 0;
  for (let item of tradeItems.value){
    canDownload = item.dealStatuses?.deal_payment_waiting?.isActive && !item.dealStatuses?.deal_paid_with_preparing_closing_documents?.isActive;

    if (downloadAll.value) {
      totalPrice.value += item.betData.current;
      if (canDownload) {
        downloadsID.value.push(item.id);
      } else {
        totalDocs.value++;
      }
    }
  }
}

let loading = ref(false);
const exportResults = async() => {
  loading.value = true;

  let api_query = {
    search: searchQuery.value.trim(),
    limit: pagesLimit.value,
    page: currentPage.value,
    order: {
      by: activeSort.value.by,
      direction: activeSort.value.direction,
    },
    position: selectedStatuses,
    dealStatuses: selectedDeals,
    date: {
      from: (dateRange.value !== null && dateRange.value.length) ? (dateRange.value[0].getTime()/1000) : null,
      to: (dateRange.value !== null && dateRange.value[1] !== null) ? (dateRange.value[1].getTime()/1000) : null,
    },
    filters: filtersQuery.value,
  }

  let apiRequest = new ApiRequest('get', 'lots/' + props.url + '/export', api_query);

  apiRequest.onSuccess((data) => {
    let a = document.createElement("a");
    a.href = window.URL.createObjectURL(data);
    a.download = "Результаты.xlsx";
    a.click();
    loading.value = false;
  });

  apiRequest.onError((error) => {

  });

  let data = await apiRequest.request();
  // console.log(data);
}

const openFilters = () => {
  // initialFilterParams.value = { ...filterParams.value };
  initialFilterParams.value = JSON.parse(JSON.stringify(filterParams.value));
  showFilters.value = true;
}

const closeFilters = () => {
  // filterParams.value = { ...initialFilterParams.value };
  filterParams.value = JSON.parse(JSON.stringify(initialFilterParams.value));
  updateCarModels();
  filterParams.value.model = initialFilterParams.value.model;
  showFilters.value = false;
}

const submitFilters = () => {
  filtersQuery.value.brandsId = [allFilters.value.brand.find(el => el.name === filterParams.value.brand)?.id];
  filtersQuery.value.modelsId = [allFilters.value.model.find(el => el.name === filterParams.value.model)?.id];
  filtersQuery.value.transmissionsId = [allFilters.value.transmission.find(el => el.name === filterParams.value.transmission)?.id];
  filtersQuery.value.citiesId = [allFilters.value.city.find(el => el.name === filterParams.value.city)?.id];
  filtersQuery.value.engineSizes.from = filterParams.value.power[0];
  filtersQuery.value.engineSizes.to = filterParams.value.power[1];
  filtersQuery.value.years.from = filterParams.value.year[0];
  filtersQuery.value.years.to = filterParams.value.year[1];
  filtersQuery.value.mileages.from = filterParams.value.odometer[0];
  filtersQuery.value.mileages.to = filterParams.value.odometer[1];

  updateTradeItems();
  showFilters.value = false;
}

watch(currentPage, updateTradeItems);

onMounted(() => {

  setTimeout(function(){
    if (statusSwiper.value !== null) statusSwiper.value.update();
    if (paramsSwiper.value !== null) paramsSwiper.value.update();
  }, 1);

  window.addEventListener('resize', handleResize, false);

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  let q_value = params.page;
  let api_query = {page: 1,};
  if (q_value) {
    currentPage.value = parseInt(q_value);
    api_query.page = currentPage.value;
  }
  getTradeItems('lots/' + props.url, api_query, true);
  getFilters();
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize, false);
});

function refreshLots() {
  updateTradeItems();
}
</script>

<style>
.trades-status .swiper-slide,
.trades-controls__filters .swiper-slide{
  width: auto;
}
.p-datepicker{
  width: 312px;
}

:root {
  /*General*/
  --dp-font-family: 'Raleway', sans-serif;
  --dp-border-radius: 5px; /*Configurable border-radius*/
  --dp-cell-border-radius: 5px; /*Specific border radius for the calendar cell*/
  --dp-common-transition: all 0.1s ease-in; /*Generic transition applied on buttons and calendar cells*/

  /*Sizing*/
  --dp-button-height: 35px; /*Size for buttons in overlays*/
  --dp-month-year-row-height: 35px; /*Height of the month-year select row*/
  --dp-month-year-row-button-size: 35px; /*Specific height for the next/previous buttons*/
  --dp-button-icon-height: 20px; /*Icon sizing in buttons*/
  --dp-cell-size: 35px; /*Width and height of calendar cell*/
  --dp-cell-padding: 5px; /*Padding in the cell*/
  --dp-common-padding: 10px; /*Common padding used*/
  --dp-input-icon-padding: 35px; /*Padding on the left side of the input if icon is present*/
  --dp-input-padding: 6px 30px 6px 12px; /*Padding in the input*/
  --dp-menu-min-width: 260px; /*Adjust the min width of the menu*/
  --dp-action-buttons-padding: 2px 5px; /*Adjust padding for the action buttons in action row*/
  --dp-row-margin:  5px 0; /*Adjust the spacing between rows in the calendar*/
  --dp-calendar-header-cell-padding: 3px; /*Adjust padding in calendar header cells*/
  --dp-two-calendars-spacing:  10px; /*Space between multiple calendars*/
  --dp-overlay-col-padding:  3px; /*Padding in the overlay column*/
  --dp-time-inc-dec-button-size:  32px; /*Sizing for arrow buttons in the time picker*/
  --dp-menu-padding: 6px 8px; /*Menu padding*/

  /*Font sizes*/
  --dp-font-size: 14px; /*Default font-size*/
  --dp-preview-font-size: 0.8rem; /*Font size of the date preview in the action row*/
  --dp-time-font-size: 0.8rem; /*Font size in the time picker*/

  /*Transitions*/
  --dp-animation-duration: 0.1s; /*Transition duration*/
  --dp-menu-appear-transition-timing: cubic-bezier(.4, 0, 1, 1); /*Timing on menu appear animation*/
  --dp-transition-timing: ease-out; /*Timing on slide animations*/
}

.dp__theme_light {
  --dp-background-color: #fff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #FC6337;
  --dp-primary-disabled-color: #6bacea;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-border-color-focus: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
  --dp-marker-color: #ff6f60;
  --dp-tooltip-color: #fafafa;
  --dp-disabled-color-text: #8e8e8e;
  --dp-highlight-color: rgb(25 118 210 / 10%);
  --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
  --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
  --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
}

</style>
<style scoped>
.preloader.for-button{
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 50%;
  width: 50%;
  z-index: 1;
}
.filters-panel{
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.25);
}
.filters-panel__bg{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.filters{
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 420px;
  border-radius: 0 15px 15px 0;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.10);
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
}
.v-enter-active {
  transition: opacity 0.4s ease;
}
.v-leave-active {
  transition: opacity 0.4s ease 0.2s;
}
.v-enter-active .filters,
.v-leave-active .filters{
  transition: transform 0.4s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.v-enter-from .filters,
.v-leave-to .filters{
  transform: translate(-100%, 0);
}

.total-enter-active,
.total-leave-active{
  transition: transform 0.4s ease;
}
.total-enter-from,
.total-leave-to{
  transform: translate(0, calc(100% + 1.3rem));
}

.filters-body{
  flex: 1 0 0;
  margin: 0 -16px 16px;
  padding: 0 16px;
  overflow-x: hidden;
  overflow-y: auto;
}
.filters .checkbox-field{
  /*padding: 8px 16px;*/
  padding: 0 16px;
}
.filters .checkbox-field label{
  font-size: 16px;
}

.form-block--filters{
  margin-bottom: 1.6rem;
}
.trades-filters{
  color: #4B4B4B;
  font-weight: 500;
}
.trades-filters__item{
  margin-bottom: 1.6rem;
  position: relative;
}
.trades-filters__item span{
  color: #FF825D;
}
.trades-filters__item .p-listbox{
  z-index: 3;
  position: absolute;
  left: -16px;
  top: 100%;
}
.filter-pages{
  font-size: 14px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  justify-content: end;
}
.filter-pages__item{
  color: #000;
  line-height: normal;
  margin-left: 4px;
  border: 1px solid #4B4B4B;
  opacity: 0.5;
  transition: all 0.3s ease;
  padding: 7px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
}
.filter-pages__item.active{
  opacity: 1;
  color: #E6E7E7;
  border-color: #FF825D;
  background: #FF825D;
  cursor: default;
}
.trades-filters__search{
  position: relative;
}
.trades-filters__search .input{
  padding-right: 48px;
  font-weight: 400;
}
.trades-filters__search .submit{
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 15px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: url('~/components/layout/img/trades/icon-search.svg') center center / contain no-repeat;
}
.trades-filters__sort{
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 8px;
}
.trades-filters__sort img{
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.trades-filters__sort img.asc{
  transform: scale(1, -1);
}
.trades-controls__filters{
  margin: 16px -16px 0;
  padding: 0 16px;
}
.trades-controls__filtersItem{
  padding: 4px 8px;
  border-radius: 10px;
  background: #E6E7E7;
  display: inline-flex;
  align-items: center;
  color: #4B4B4B;
  font-size: 14px;
}
.trades-controls__filtersItem .remove{
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-left: 9px;
  background: url('~/components/layout/img/trades/icon-remove.svg') center center / contain no-repeat;
}
.trades-filters__statuses{
  color: #8C8C8C;
  font-size: 14px;
}
.trades-filters__statuses > .col-12{
  margin-top: 16px;
}
.status-label{
  display: inline-block;
  margin-right: 24px;
}
.trades-status .checkbox-field label{
  padding-left: 28px;
}
.date-icon{
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate(0, -50%);
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('~/components/layout/img/trades/icon-calendar.svg') center center / contain no-repeat;
}
.car-item__row{
  display: flex;
  align-items: center;
}
.car-item__row .checkbox-field{
  margin-bottom: 32px;
  display: block;
}
.filter-close{
  position: absolute;
  font-size: 16px;
  top: 16px;
  right: 16px;
  cursor: pointer;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}
.filter-close:before{
  content: '';
  position: relative;
  transition: all 0.3s ease;
  width: 17px;
  height: 17px;
  background: url('../../components/layout/img/icon-close.svg') center center / contain no-repeat;
}
.filter-close:hover:before{
  transform: rotate(90deg);
}
.form-block--trades{
  position: relative;
}
.refresh {
  margin-right: 1rem;
}
@media(min-width: 1400px){
  .trades-filters__search{
    width: 256px;
    margin-left: 8px;
  }
  .trades-status .checkbox-field label{
    font-size: 16px;
  }
}
@media(max-width: 991px){
  .car-item__row{
    flex-wrap: wrap;
  }
  .status-label{
    margin-bottom: 0.6rem;
  }
  .trades-status{
    margin: 0 -16px;
    padding: 0 16px;
  }
}
@media(min-width: 576px){
  .trades-filters__date{
    width: 312px;
  }
}
@media(max-width: 575px){
  .trades-filters{
    font-size: 14px;
  }
  .form-block--trades{
    background: none;
    border-radius: 0;
    padding: 0;
  }
  .refresh{
    display: none;
  }
}
@media(max-width: 420px){
  .filters{
    border-radius: 0;
  }
}
</style>
