<script setup lang="ts">
  import { ref } from 'vue';
  import {ApiRequest} from "~/utils/api-request";
  import Preloader from "~/components/page/Preloader.vue";

  const props = defineProps({
    selected: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    },
    price: {
      type: Number,
      default: 0
    },
    noDocs: {
      type: Number,
      default: 0
    },
    url: {
      type: String,
      default: 'deal'
    },
    documents: {
      type: Array,
      default: []
    }
  });

  let priceOnly = (props.url === 'finished-deal');

  let loading = ref(false);

  const formatNumber = (num) => {
    // return num.toLocaleString().replace(/,/g, " ");
    return num.toLocaleString('ru');
  }

  const downloadAll = async() => {
    loading.value = true;
    let api_query = {
      ids: props.documents
    }
    let apiRequest = new ApiRequest('get', 'lots/'+ props.url +'/archives', api_query);
    apiRequest.onSuccess((data) => {
      // console.log(data);
      if (data.path) {
        let link = document.createElement('a');
        link.setAttribute('href', data.path);
        link.setAttribute('download', 'Документы.zip');
        link.click();
      }
      loading.value = false;
    });
    apiRequest.onError((error) => {

    });

    let data = await apiRequest.request();
    // console.log(data);
  }
</script>

<template>
  <div class="documents">
    <div class="row align-items-center justify-content-between">
      <div class="col-12 col-md-auto position-relative">
        <Preloader v-if="loading" />
        <button
            type="button"
            class="button --compact button--light button--download text-left"
            :class="{'--loading': loading}"
            v-if="!priceOnly && (selected - noDocs) && documents.length"
            @click="downloadAll"
        >
          <span>Скачать документы <br class="d-lg-none d-xl-inline d-xxl-none">по выбранным лотам ({{selected - noDocs}})</span>
        </button>
      </div>
      <div class="col-12 col-md-auto">
        <div class="documents-total">
          <div class="row align-items-center justify-content-between">
            <div class="col-12 col-sm-auto">
              Выбрано лотов: <span>{{selected}}</span> из {{ total }}<br>
              <template v-if="noDocs && !priceOnly">Документы отсутствуют: <span>{{noDocs}}</span></template>
            </div>
            <div class="col-12 col-sm-auto documents-total__price">
              <small>Сумма:</small>
              <strong>{{formatNumber(price)}} ₽</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.preloader{
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 50%;
  width: 50%;
  z-index: 1;
}
.documents{
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  border-radius: 15px 15px 0 0;
  background: #fff;
  box-shadow: 4px 0 8px 0 rgba(0, 0, 0, 0.10);
  padding: 26px 32px;
  z-index: 10;
}
@media(min-width: 1200px){
  .documents{
    transform: translate(0, 1.3rem);
  }
}
.documents-total{
  border-radius: 10px;
  background: #E6E7E7;
  padding: 8px 16px;
  color: #000;
  font-size: 16px;
  line-height: 1.3125;
  display: inline-block;
}
.documents-total span{
  color: #FC6337;
}
.documents-total__price{
  line-height: normal;
}
.documents-total__price small{
  color: #8C8C8C;
  font-size: 12px;
  font-weight: 500;
}
.documents-total__price strong{
  font-weight: 400;
  display: block;
  margin-top: 4px;
  font-size: 20px;
}
@media(max-width: 767px){
  .documents-total{
    margin-top: 16px;
  }
}
@media(max-width: 575px){
  .documents-total,
  .documents .button{
    width: 100%;
  }
  .documents-total__price{
    margin-top: 16px;
  }
  .documents .button{
    justify-content: space-between;
  }
}
</style>
